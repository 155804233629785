import React from "react";
import { Link } from 'react-router-dom'
import hero1 from '../../images/slider/2.png'
import shape1 from '../../images/slider/shape-4.png'
import shape2 from '../../images/slider/shape-5.png'
import Fade from 'react-reveal/Fade';

const Hero5 = () => {
    return (
        <section className="static-hero-s2">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div className="hero-content wpo-dark-blue-background">
                            <div className="slide-title">
                                <h2>How do we do this</h2>
                            </div>
                            <div><b>Proven Industry Experience:</b> Our team's extensive experience in core systems transformations forms the foundation of our expertise. We've navigated paths similar to yours, equipping us with valuable insights to guide you confidently towards success. We understand the journey and are committed to applying our knowledge to help you achieve your goals effectively.</div>
                            <p/>
                            <div><b>Tailored Transformation Strategies:</b> We believe in a personalized approach. Every insurer is unique, and so is our solution for you. We align our strategies with your specific needs to ensure optimal outcomes.</div>
                            <p/>
                            <div><b>Data-Driven Decision Making:</b>Harnessing the power of data, we set a robust baseline for growth and use predictive insights to forecast future trends. By analyzing past performance in retrospection, we glean valuable lessons, and through optimization, we fine-tune processes for maximum efficiency. This streamlined, data-driven approach is your pathway to achieving amplified success.</div>
                            <p/>
                            <div><b>Enhancing Team Effectiveness:</b> The members of our team integrate seamlessly into existing teams, elevating them by providing mentoring and leadership. With a steadfast dedication to innovation and excellence, we utilize and optimize processes and tools to guide enterprises toward thriving in an ever-evolving digital landscape. We provide regular updates, feedback mechanisms, and transparent reporting to keep all stakeholders informed and engaged.</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-line-shape-1"><img src={shape1} alt=""/></div>
            <div className="hero-line-shape-2"><img src={shape2} alt=""/></div>
        </section>
    )
}

export default Hero5;