import React from "react";
import { Link } from 'react-router-dom'
import SectionTitle from "../SectionTitle/SectionTitle";
import HowWeDoIt from "../ProjectSection/HowWeDoIt";
import Services from '../../api/service'
import smg1 from '../../images/service/shape-2.png'
import smg2 from '../../images/service/shape-3.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const ServiceSectionS3 = () => {
    return (
        <section className="wpo-service-section-s4 section-padding">
            <div className="container">
                <SectionTitle subTitle={'Our Services'} Title={'What we do'} />
                <div>Our team consists of <b>exceptional system specialists</b> dedicated to the insurance sector, with a proven history of success across insurers of all sizes. Each member is highly qualified, certified, and experienced, ensuring a deep understanding of the industry's specific challenges. We are committed to <b>guiding and mentoring your teams</b> through your transformation journey. Partnering with us means gaining more than just a service provider; it means having a <b>committed ally</b> equipped to navigate the complexities of insurance with proven expertise and insight.</div>
                <p/>
                <div>The members of our team integrate seamlessly into existing teams, elevating them by providing mentoring and leadership. Dedicated to innovation and excellence, we utilize and optimize processes and tools to guide enterprises toward thriving in an ever-evolving digital landscape. We provide <b>regular updates, feedback mechanisms, and transparent reporting</b> to keep all stakeholders informed and engaged.</div>
            </div>
        </section>
    );
}

export default ServiceSectionS3;