import timg2 from '../images/team/dominik.jpg'
import timg1 from '../images/team/gatis.jpg'
import gatis_small from '../images/team/gatis_small.jpg'



const Teams = [
    {
        Id: 'gatis',
        tImg:timg1,
        smallImg:gatis_small,
        name:'Gatis Vuguls',
        firstName:'Gatis',
        title:'Co-founder',
        titleDetailed: 'Global Insurance Technology Lead',
        linkedIn: 'https://www.linkedin.com/in/gatis-vuguls-7502a133/',
        teamInfoSummary: `<p>My journey in the insurance industry started at the Guidewire Services Center (GSC) in Dublin, where I was among the first members to join the team. Since then, I've had the fantastic opportunity to work with insurance companies on 4 continents - Australia, Africa, America, and Europe. My expertise spans across both personal and commercial lines of business and I have worked with insurers of all shapes and sizes.<\p>
        
        <p>Over the years, I’ve worked with many amazing people who truly understand insurance and technology. This experience has taught me to recognize the valuable traits that these individuals possess, such as their deep knowledge, innovative thinking, and collaborative spirit. I plan to leverage this insight to build an amazing team at Pollatir, focusing on harnessing these good traits to drive our mission forward.</p>

        <p>What sets me apart is my hands-on approach, having been involved with all aspects of complex core transformation projects; from starting up projects to implementing, upgrading, and maintaining complex insurance systems. I'm not a fan of inefficiencies and love finding ways to get things done faster and better. I enjoy a good challenge and thrive in environments where I'm constantly pushed to solve tough problems.<\p>
        
        <p>Outside of work, I'm a happy husband and dad to two amazing kids. Balancing a busy professional life with a happy home life is important to me, and I bring that same level of dedication and passion to everything I do.<\p>`,
    },
    {
        Id: 'dominik',
        tImg:timg2,
        smallImg:timg2,
        name:'Dominik Piskunowicz',
        firstName:'Domink',
        title:'CEO',
        linkedIn: 'https://www.linkedin.com/in/dominikp/',
        teamInfoSummary: `<p>I'm a grateful husband and a happy father of four. Living in a house on the outskirts of a small city in northern Poland I'm a passionate, albeit not quite pro, golf player.</p>

        <p>My journey in software development started back in 1990 when I bravely “re-typed” my first ever Basic code. I've even dabbled as a Linux administrator once, managing to recompile the Linux kernel, though the results were... let's just say, interesting.</p> 

        <p>Professionally, I've been in the IT industry since 2004, mainly focusing on software development. I've had the privilege of working with teams across the globe - Poland, France, Ireland, Scotland, Switzerland, Canada, USA, England, and Germany. My real passion lies in programming, software design, architecture, and yes, getting my hands dirty in the code. 

        <p>I'm a seasoned software expert in P&C and Life with over 13 years of knowledge. I’m a software architect by heart, always hunting for the best possible solution. I have over a decade of experience in delivering Guidewire projects across PolicyCenter, ClaimCenter, BillingCenter, and Digital. I'm particularly adept in Guidewire Integrations, focusing on the Guidewire Cloud Platform (GWCP) transition processes and standards.</p>`,
    }
]

export default Teams;