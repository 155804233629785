import React, {Fragment} from 'react';
import ServiceSectionS3P1 from '../../components/ServiceSectionS3/ServiceSectionS3P1';
import ServiceSectionS3P2 from '../../components/ServiceSectionS3/ServiceSectionS3P2';
import Navbar3 from '../../components/Navbar3/Navbar3';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import PageTitle from '../../components/pagetitle/PageTitle';
import Hero5 from '../../components/hero5/Hero5';

const ServicePage =() => {
    return(
        <Fragment>
            <Navbar3 hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
            <PageTitle pageTitle={'Services'} pagesub={'Services'}/> 
            <ServiceSectionS3P1/>
            <Hero5/>  
            <ServiceSectionS3P2/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicePage;

