import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'

import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../images/service-single/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'





const Services = [
    {
        Id: '1',
        sImg: simg,
        sImgS: sImgS1,
        sTitle: 'Project Planning & Initial Setup',
        description: 'Our services emphasize collaboration from the outset, aiding in the initial planning to ensure your projects have a strong and clear direction. We partner with you to establish a Target Operating Model (TOM), implement efficient guardrails, define processes and develop robust governance structures, aligning closely with your goals.',
        des2: '',
        des3: '',
        icon: 'flaticon-advisor',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,


    },
    {
        Id: '2',
        sImg: simg3,
        sImgS: sImgS3,
        sTitle: 'Specialized Consulting:',
        description: 'Our team is adept at addressing a range of challenges, from resolving performance issues in applications to implementing groundbreaking solutions and salvaging troubled projects. We are committed to delivering innovative and tailored consulting services to navigate and resolve your unique business challenges effectively.',
        des2: '',
        des3: '',
        icon: 'flaticon-taxes',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '3',
        sImg: simg2,
        sImgS: sImgS2,
        sTitle: 'Build Your Own Team',
        description: 'Recognizing the increasing trend of insurers viewing IT operations as a core business element rather than just an expense. Building a capable team to manage core transformation projects can be challenging. We provide guidance in identifying key roles and necessary skillsets, along with advising on suitable training programs and certification paths. Our goal is to empower you to build a proficient team that can effectively drive your IT transformation initiatives.',
        des2: '',
        des3: '',
        icon: 'flaticon-team',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '4',
        sImg: simg4,
        sImgS: sImgS4,
        sTitle: 'Architecture and Hands-on Development',
        description: 'We offer a comprehensive suite of services tailored to the insurance industry, encompassing the design of robust, scalable, and efficient solutions, as well as hands-on development. We create solutions that not only meet the unique needs of your business but also adhere to best practices and industry standards, ensuring operational efficiencies from strategy through to deployment.',
        des2: '',
        des3: '',
        icon: 'flaticon-tax',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    }
]

export default Services;