import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/about/values3.jpg'
import shape1 from '../../images/about/shape-1.png'
import shape2 from '../../images/about/shape-2.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About2 = (props) => {
    return(
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <div className="inner-img">
                                    <img src={abimg} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <h2>Our values</h2>
                            <p>We are committed to fostering a collaborative partnership with you, deeply grounded in integrity, 
                                dedicated to empowering your people and promoting  excellence within your organization, driving sustainable delivery through shared goals and values.</p>
                            
                            <div className="text-sub-item">
                                <h4>Collaboration</h4>
                                <div>Drive a collaborative culture, working together with our customers, their partners, and our team.</div>
                            </div>
                            <div className="text-sub-item">
                                <h4>Integrity</h4>
                                <div>Engage with all parties honestly, with transparency using data driven decision making.</div>
                            </div>
                            <div className="text-sub-item">
                                <h4>Empowerment</h4>
                                <div>Cultivate an environment where everyone can make  informed decisions, contributing to collective success.</div>
                            </div>
                            <div className="text-sub-item">
                                <h4>Commitment to Excellence</h4>
                                <div>Constantly promoting best practices to achieve optimal results.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1"><img src={shape1} alt=""/></div>
            <div className="shape-2"><img src={shape2} alt=""/></div>
        </section>
    )
}

export default About2;