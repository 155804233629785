import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/about/why_us.jpg'

const ClickHandler = (props) => {
    window.scrollTo(10, 0);
}


const About4 = (props) => {
    return (
        <section className="wpo-about-section-s4 section-padding pb-120">
            <div className="container">
                <div className="wpo-about-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <h2>Why choose us?</h2>
                                <p>Our ethos is that we are not another System Integrator; we are a collective of problem solvers. Our team prides itself on being the drivers of change, steering your business towards new horizons and innovative solutions. We see ourselves as the enablers of your success, dedicated to ensuring your goals are not just met but exceeded. With a spirit of collaboration and a commitment to excellence, we invite you to partner with us. Help us help you in crafting a future that's not only transformative but also successful.</p>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <div className="inner-img">
                                        <img src={abimg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About4;