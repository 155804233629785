import React from "react";
import { Link } from 'react-router-dom'
import SectionTitle from "../SectionTitle/SectionTitle";
import Services from '../../api/service'
import smg1 from '../../images/service/shape-2.png'
import smg2 from '../../images/service/shape-3.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const ServiceSectionS3 = () => {
    return (
        <section className="wpo-service-section-s4 section-padding">
            <div className="container">
                <SectionTitle subTitle={''} Title={'Where We Can Help'} />
                
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12 services" key="1" >
                        <div className="wpo-service-item">
                            <div className="wpo-service-text service-content">
                                <div className="wpo-service-single-title">
                                    <h3>Project planning & setup</h3>
                                </div>
                                <ul>
                                    <li>Negotiate scope with the business to ensure product delivers value</li>
                                    <li>Define conceptual sprint plan and map out dependencies</li>
                                    <li>Implement guardrails and processes to help with efficiency</li>
                                    <li>Develop robust governance structures aligned with client goals</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-12 col-12 services" key="2">
                        <div className="wpo-service-item">
                            <div className="wpo-service-text service-content">
                                <div className="wpo-service-single-title">
                                    <h3>Specialized Consulting</h3>
                                </div>
                                <ul>
                                    <li>Implementing innovative products utilizing the power of OOTB features </li>
                                    <li>Identifying and resolving performance issues</li>
                                    <li>Advising troubled projects on how to put them back on track</li>
                                    <li>Performing reviews to evaluate if solutionx adheer to standards and best practices</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 services" key="3">
                        <div className="wpo-service-item">
                            <div className="wpo-service-text service-content">
                                <div className="wpo-service-single-title">
                                    <h3>Build Your Own Team</h3>
                                </div>
                                <ul>
                                    <li>Identify key roles and necessary skillsets</li>
                                    <li>Creating training programs and certification paths</li>
                                    <li>Help you with building a capable team</li>
                                    <li>Assist with defining the target operation model (TOM) for your internal team</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 services" key="4">
                        <div className="wpo-service-item">
                            <div className="wpo-service-text service-content">
                                <div className="wpo-service-single-title">
                                    <h3>Architecture & Hands-on Development</h3>
                                </div>
                                <ul>
                                    <li>Implement architecture guardrails and processes</li>
                                    <li>Integrate products with third party solutions</li>
                                    <li>Build new and enhance existing products</li>
                                    <li>Perform upgrades</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
            
        </section>
    );
}

export default ServiceSectionS3;