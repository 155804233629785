import React from "react";
import shape from '../../images/ct-shape.png'


const ContactInfo = (props) => {
    return (
        <section className="contact-info-section section-padding">
            <div className="container">
                <div className="office-info">
                    <div className="row">
                        <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                            <div className="office-info-item">
                                <a href="https://www.linkedin.com/company/pollatir/">
                                    <div className="office-info-icon">
                                        <div className="icon">
                                            <i className="fi flaticon-linkedin"></i>
                                        </div>
                                    </div>
                                </a>
                                <div className="office-info-text">
                                    <p><a class="no-blue-link" href="https://www.linkedin.com/company/pollatir/">Pollatir on LinkedIn</a></p>
                                    <p>{'\u00A0'}</p>
                                    <span>follow us</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                            <div className="office-info-item active">
                                <div className="office-info-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-email"></i>
                                    </div>
                                </div>
                                <div className="office-info-text">
                                    <p><a class="no-blue-link" href="mailto:contact@pollatir.com">contact@pollatir.com</a></p>
                                    <p>{'\u00A0'}</p>
                                    <span>EMAIL ADDRESS</span>
                                </div>

                            </div>
                        </div>
                        <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                            <div className="office-info-item">
                                <div className="office-info-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-place"></i>
                                    </div>
                                </div>
                                <div className="office-info-text">
                                    <p>ul. Żelazna 51/53</p>
                                    <p>00-841 Warszawa</p>
                                    <span>headquarters</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ct-shape">
                <img src={shape} alt="shape"/>
            </div>
        </section>
    );
}

export default ContactInfo;
