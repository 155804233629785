import React from 'react'
import { Link } from 'react-router-dom'
import abimg2 from '../../images/about/4.jpg'
import shape1 from '../../images/ab-shape-1.png'
import shape2 from '../../images/ab-shape-2.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About = (props) => {
    return (
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={props.abimg} alt="" />
                                <div className="wpo-ab-shape-1"><img src={shape1} alt="" /></div>
                                <div className="wpo-ab-shape-2"><img src={shape2} alt="" /></div>
                                <div className="wpo-about-exprience">
                                    <h3>Trusted Partner</h3>
                                </div>
                                <div className="wpo-ab-img-2">
                                    <img src={abimg2} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <h2>Steering your business towards <b>new horizons and <span>innovative</span> solutions.</b>
                            </h2>
                            <p>We are the enablers of your success, dedicated to ensuring your goals are not just met but exceeded. With a spirit of collaboration and a commitment to excellence, we invite you to partner with us. We are committed to guiding and mentoring your teams through your transformation journey.</p>
                            
                            <p> Partnering with us means gaining more than just a service provider; it means having a trusted ally equipped to navigate the complexities of insurance with proven expertise and insight. Help us help you in crafting a future that's not only transformative but also successful.</p>
                            <Link onClick={ClickHandler} className="theme-btn" to="/about">More About Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;