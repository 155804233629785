import React from 'react'
import simg from '../../images/mission-shape.png'
import SectionTitle from "../SectionTitle/SectionTitle";

const MissionVission = (props) => {
    return (
        <section className="wpo-mission-vission-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-mission-item">
                            <div className="wpo-mission-text">
                                <h2>Our Mission</h2>
                                <div><b>Empower our clients</b> to achieve their business objectives by offering <b>innovative, best-in-class IT services</b>.
                                    We are committed to <b>reducing costs</b> through efficient implementations, <b>unlocking the full potential</b> of Commercial Off-The-Shelf (COTS) systems.
                                    Our mission is to <b>maximise value, mitigating implementation risks</b> by establishing robust processes and fostering collaborative communication, and elevating the overall quality of IT transformation projects.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-vission-item">
                            <div className="wpo-vission-text">
                                <h2>Our Vision</h2>
                                <div>A future where insurers <b>harness technology</b> to its fullest potential.<br/> Walking the path of sustainable tech advancements that empower them to <b>optimise their business performance</b>.<br/>Our vision is a world where every technology decision is made with a clear understanding of both the <b>opportunities</b> and the <b>challenges</b> impacting their business.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MissionVission;