import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Team from '../../api/team';
import shape1 from '../../images/team/shape6.png'
import shape2 from '../../images/progress-shape-1.png'
import shape3 from '../../images/progress-shape-2.png'


const TeamSinglePage = (props) => {
    const { id } = useParams()

    const TeamDetails = Team.find(item => item.Id === id)

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    function createMarkup(htmlString) {
        return { __html: htmlString };
    }

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'}/>
            <div className="team-pg-area">
                <div className="team-inner section-padding">
                    <div className="container">
                        <div className="team-info-wrap">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="team-info-img">
                                        <img src={TeamDetails.tImg} alt=""/>
                                        <div className="shape"><img src={shape1} alt=""/></div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="team-info-text">
                                        <h2>{TeamDetails.name}</h2>
                                        <span>{TeamDetails.titleDetailed}</span>
                                        <div dangerouslySetInnerHTML={createMarkup(TeamDetails.teamInfoSummary)} />
                            
                                        <ul className="connect-team">
                                            <li>Connect:</li>
                                            <li>
                                                <a href={TeamDetails.linkedIn}>
                                                    <i className="ti-linkedin"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pr-shape-1"><img src={shape2} alt=""/></div>
                    <div className="pr-shape-2"><img src={shape3} alt=""/></div>
                </div>
            </div>
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};
export default TeamSinglePage;
