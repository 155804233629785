import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Scrollbar from '../../components/scrollbar/scrollbar'
import MissionVission from '../../components/MissionVission/MissionVission';
import About4 from '../../components/about4/about4';
import About2 from '../../components/about2/about2';
import Footer from '../../components/footer/Footer';
import Testimonial2 from '../../components/Testimonial2/Testimonial2-P1';



const AboutPage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'}/>
            <About2/>
            <About4/>
            <MissionVission/>
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
